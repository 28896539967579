import { useField } from 'formik';
import { forwardRef, useContext } from 'react';
import { SelectInstance } from 'react-select';
import { FieldContext } from 'Shared/Contexts';
import { AddressField as BaseAddressField, AddressFieldProps, OptionProps } from 'Shared/Components/FormFields/AddressField';

export const AddressField = forwardRef<SelectInstance<OptionProps>, AddressFieldProps>((props, ref) => {
  const { name } = useContext(FieldContext);

  if (name === '') {
    throw new Error(AddressField.displayName + ' with missing FieldWrapper component');
  }

  const [{ value }, , { setValue, setTouched }] = useField(name);

  const modifiedProps: AddressFieldProps = {
    ...props,
    name,
    value,
    onBlur: () => setTouched(true),
    onChange: (value) => setValue(value),
    instanceId: `react-select-${name}`,
  };

  return <BaseAddressField {...modifiedProps} ref={ref} />;
});

AddressField.displayName = 'FormikAddressField';
