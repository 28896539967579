import FillerImage from 'Images/auth-background.jpg';
import LogoImage from 'Images/logo_stacked.svg';
import { useEffect } from 'react';
import { toast, ToastContainer, TypeOptions } from 'react-toastify';
import { useCurrentPage, useInitialApplication } from 'Shared/Hooks';

const useFlashNotifications = () => {
  const { flash } = useCurrentPage<{ flash?: { type: TypeOptions; message: string }[] }>();
  useEffect(() => {
    if (Array.isArray(flash)) {
      flash.forEach(({ type, message }) => {
        toast(message, { type });
      });
    }
  }, [JSON.stringify(flash)]);
};

function AuthLayout({ children }) {
  const { site_name } = useInitialApplication();
  const { page_title } = useCurrentPage<{ page_title?: string }>();

  useEffect(() => {
    document.title = page_title ? `${page_title} | ${site_name}` : site_name;
  }, [page_title, site_name]);

  useFlashNotifications();

  return (
    <>
      <ToastContainer autoClose={5000} pauseOnFocusLoss pauseOnHover theme="light" position="top-right" />
      <div className="flex h-screen w-screen max-w-screen bg-white">
        <div className="flex h-full w-full max-w-2xl flex-shrink-0 flex-col items-center overflow-auto px-4 py-12 md:w-1/2">
          <div className="mt-4 h-16 w-full max-w-sm">
            <img className="h-full" src={LogoImage} alt={`${site_name} Logo`} />
          </div>
          <div className="my-auto w-full max-w-sm">{children}</div>
        </div>
        <div className="relative hidden flex-grow md:block">
          <img className="h-full w-full object-cover object-right" src={FillerImage} alt="Data Lines Image" />
        </div>
      </div>
    </>
  );
}

export default AuthLayout;
