import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import { Link } from '@inertiajs/react';
import cls from 'classnames';
import { PropsWithChildren, ReactNode } from 'react';
import { PageWidthConstraint } from './PageWidthConstraint';

export type PageHeaderProps = PropsWithChildren<{
  backLink?: string;
  className?: string;
  title: string;
  subTitle?: ReactNode;
  action?: string;
  nextToHeaderContent?: ReactNode;
}>;

const BackLink = ({ href }) => {
  if (!href) return null;

  const buttonAttributes = {
    style: { boxShadow: '0px 11px 12px -5px rgba(107, 98, 119, 0.3)' },
    className:
      'bg-white inline-flex h-11 w-11 mr-4 items-center justify-center rounded-full text-indigo-500 hover:text-indigo-600 focus:text-gray-500 focus:outline-none transition ease-in-out duration-150',
  };

  if (href === true) {
    return (
      <button type="button" onClick={() => window.history.back()} {...buttonAttributes}>
        <ArrowLeftIcon className="h-5" />
      </button>
    );
  }

  return (
    <Link href={href} {...buttonAttributes}>
      <ArrowLeftIcon className="h-5" />
    </Link>
  );
};

export const PageHeader = ({
  backLink = undefined,
  className = '',
  title,
  subTitle = '',
  children = null,
  action = '',
  nextToHeaderContent = undefined,
}: PageHeaderProps) => (
  <PageWidthConstraint className={cls('mt-8 space-y-2', className)}>
    <div className="flex flex-wrap items-center justify-between sm:flex-nowrap">
      <div className={cls('min-h-12', !subTitle && 'flex items-center')}>
        <div className="mr-4 flex flex-wrap items-center sm:flex-nowrap">
          <BackLink href={backLink} />
          <h1 className="text-3xl text-black">{title}</h1>
          {nextToHeaderContent}
        </div>
        {subTitle && <div className="mt-2 text-gray-800">{subTitle}</div>}
      </div>
      {children || action}
    </div>
  </PageWidthConstraint>
);
