import cls from 'classnames';
import { ElementType, forwardRef, ReactElement } from 'react';
import { Box, PolymorphicComponentProps } from 'react-polymorphic-box';

export type BaseButtonOwnProps = {
  className?: string;
};

export type BaseButtonProps<E extends ElementType> = PolymorphicComponentProps<E, BaseButtonOwnProps>;

export const defaultElement = 'button';

export type BaseButtonWrapper = <E extends ElementType = typeof defaultElement>(props: BaseButtonProps<E>) => ReactElement | null;

export const BaseButton: BaseButtonWrapper = forwardRef(function BaseButton<E extends ElementType = typeof defaultElement>(
  { className = '', ...otherProps }: BaseButtonProps<E>,
  ref: typeof otherProps.ref,
) {
  // look for h-12 but not max-h-12 or mah-12
  const additionalClasses = className.match(/(?:^|\s)h-\d+(?:$|\s)/) ? '' : 'h-11';

  return <Box as={defaultElement} className={cls(additionalClasses, className)} ref={ref} {...otherProps} />;
});
