import { InformationCircleIcon } from '@heroicons/react/20/solid';
import cls from 'classnames';
import { PropsWithChildren, ReactElement, ReactNode, JSXElementConstructor } from 'react';

export type VerticalFieldWrapperProps = PropsWithChildren<{
  label: string;
  labelClassName?: string;
  errorComponent?: ReactElement;
  className?: string;
  name?: string;
  hint?: ReactNode;
  as?: JSXElementConstructor<any> | keyof JSX.IntrinsicElements;
  isRequired?: boolean;
}>;

export const VerticalFieldWrapper = ({
  label,
  labelClassName = 'text-gray-800 text-sm',
  errorComponent,
  className,
  name,
  children,
  hint,
  as = 'label',
  isRequired = false,
}: VerticalFieldWrapperProps) => {
  const WrapperElement = as;
  const LabelElement = as === 'label' ? 'div' : 'label';

  return (
    <WrapperElement className={cls('space-y-2 block', className)}>
      <LabelElement className={labelClassName} htmlFor={LabelElement === 'label' && name !== undefined ? `field_${name}` : undefined}>
        {label}
        {isRequired && <span className="text-red">*</span>}
      </LabelElement>
      <div>
        <div className="relative">{children}</div>
        {errorComponent}
        {hint && (
          <div className="text-sm pt-2 text-gray-750 flex items-start leading-normal">
            <InformationCircleIcon className="mr-1 mt-1 flex-shrink-0 text-cyan h-4" /> {hint}
          </div>
        )}
      </div>
    </WrapperElement>
  );
};
