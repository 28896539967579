import { Field } from 'formik';
import { forwardRef, InputHTMLAttributes, useContext } from 'react';
import { FieldContext } from 'Shared/Contexts';
import { CheckboxField as BaseCheckboxField } from 'Shared/Components/FormFields/CheckboxField';

export const CheckboxField = forwardRef<HTMLInputElement, InputHTMLAttributes<HTMLInputElement>>((props, ref) => {
  const { name } = useContext(FieldContext);

  if (name === '') {
    throw new Error(CheckboxField.displayName + ' with missing FieldWrapper component');
  }

  return <Field as={BaseCheckboxField} ref={ref} name={name} {...props} />;
});

CheckboxField.displayName = 'FormikCheckboxField';
