import { ReactElement } from 'react';

export function ErrorComponent({ children }: { children: ReactElement }) {
  const baseClassName = 'bg-red text-white p-2 text-xs ml-auto -mt-1 pt-3 shadow-sm rounded';

  if (typeof children === 'object') {
    const messages = Object.entries(children);
    return (
      <ul className={baseClassName}>
        {messages.map(([column, message]) => (
          <li key={column} className="py-0.5">
            {message}
          </li>
        ))}
      </ul>
    );
  }

  return <div className={baseClassName}>{children}</div>;
}
