import { ErrorMessage, FormikContext } from 'formik';
import getFromPath from 'lodash.get';
import * as React from 'react';
import { useContext } from 'react';
import { FieldContext, FormikContext as ValidationFormikContext } from 'Shared/Contexts';
import { VerticalFieldWrapper as BaseVerticalFieldWrapper, VerticalFieldWrapperProps } from 'Shared/Components/FormFieldWrappers/VerticalFieldWrapper';
import { SchemaDescription } from 'yup';
import { ErrorComponent } from './ErrorComponent';

type FormikVerticalFieldWrapperProps = VerticalFieldWrapperProps & {
  name: string;
  ignoreMissing?: boolean;
  requiredStar?: boolean;
};

export const VerticalFieldWrapper = ({ name, ignoreMissing, requiredStar, children, ...props }: FormikVerticalFieldWrapperProps) => {
  const { validationSchema } = useContext(ValidationFormikContext);
  const { initialValues } = useContext(FormikContext);

  if (!ignoreMissing && getFromPath(initialValues, name) === undefined) {
    throw new Error(`Field '${name}' not registered in formik initial values`);
  }

  const field = validationSchema.describe().fields[name] as SchemaDescription | undefined;
  const isRequired = requiredStar !== false && (requiredStar === true || (field?.type !== 'boolean' && field?.optional === false));

  return (
    <FieldContext.Provider value={{ name }}>
      <BaseVerticalFieldWrapper
        name={name}
        isRequired={isRequired}
        errorComponent={<ErrorMessage name={name} component={ErrorComponent} />}
        {...props}
      >
        {children}
      </BaseVerticalFieldWrapper>
    </FieldContext.Provider>
  );
};

VerticalFieldWrapper.displayName = 'FormikVerticalFieldWrapper';
