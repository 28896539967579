import cls from 'classnames';
import { ElementType, forwardRef } from 'react';
import { BaseButton, BaseButtonProps, BaseButtonWrapper, defaultElement } from './BaseButton';

export const FlatButton: BaseButtonWrapper = forwardRef(function FlatButton<E extends ElementType = typeof defaultElement>(
  { className, ...otherProps }: BaseButtonProps<E>,
  ref: typeof otherProps.ref,
) {
  const baseClasses =
    'transition transition-colors duration-300 whitespace-nowrap font-medium text-gray-900 text-sm border rounded px-4 inline-block flex items-center justify-center hover:border-cyan hover:text-cyan disabled:hover:border-gray-300 disabled:hover:text-gray-900 disabled:cursor-not-allowed';

  return <BaseButton as={defaultElement} className={cls(baseClasses, className)} ref={ref} {...otherProps} />;
});
