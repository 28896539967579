import { CalendarIcon } from '@heroicons/react/24/outline';
import cls from 'classnames';
import { format as formatDate, parseISO, startOfDay } from 'date-fns';
import { useField } from 'formik';
import { forwardRef, LegacyRef, useEffect, useState } from 'react';
import { CustomDatePicker } from 'Shared/Components';
import { FieldPostfix } from 'Shared/Components/Formik';

const CustomInput = forwardRef((props: { className: string; onClick: (e: any) => void }, ref: LegacyRef<HTMLInputElement> | undefined) => {
  const customProps = {
    ...props,
    className: cls(props.className, 'cursor-text'),
    onClick(e) {
      e.preventDefault();
      props.onClick(e);
    },
  };

  return (
    <div className="relative inline-block cursor-text">
      <input ref={ref} type="date" {...customProps} />
      <FieldPostfix children={<CalendarIcon className="h-5" />} />
    </div>
  );
});

export const ReactDateField = ({ valueKey, labelKey, ...props }) => {
  const [field, state, { setValue, setTouched }] = useField(props.field.name);
  const [startDate, setStartDate] = useState(field.value ? new Date(parseISO(field.value)) : null);

  useEffect(() => {
    setStartDate(field.value ? new Date(parseISO(field.value)) : null);
  }, [field.value]);

  let format = 'yyyy-MM-dd';
  let maxDate = props.max ? parseISO(props.max) : null;
  let minDate = props.min ? parseISO(props.min) : null;
  if (props.max === 'today') {
    maxDate = startOfDay(new Date());
  }

  if (props.min === 'today') {
    minDate = startOfDay(new Date());
  }

  if (props.max === 'now') {
    maxDate = new Date();
  }

  if (props.min === 'now') {
    minDate = new Date();
  }

  const onChange = (selectedDate) => {
    try {
      setValue(formatDate(selectedDate, format));
      setStartDate(selectedDate);
    } catch (e) {}
  };

  if (props.disabled) {
    return (
      <input
        type="date"
        className={`h-10.5 flex items-center justify-between shadow-input bg-gray-100 pl-4 spinners-none input-icon-mask
        text-blackish rounded placeholder-gray-800 text-sm py-2.5 w-32 border border-gray-600 focus-ring focus:bg-white focus:shadow:none
        `}
        value={field.value}
        disabled
      />
    );
  }

  return <CustomDatePicker date={startDate} setDate={onChange} min={minDate} max={maxDate} placeholderText={props.placeholder} />;
};
