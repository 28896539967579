import AuthLayout from 'Layouts/AuthLayout';
import { ReactNode } from 'react';
import { initInertiaApp } from 'Shared/Support/init';
import 'Style/app.css';
import { axios } from 'Shared/Support';
window.axios = axios;

const moduleLoaders = import.meta.glob<{ default: ReactNode }>('./Pages/**/*.tsx');

initInertiaApp({
  resolve: async (name) => (await moduleLoaders[`./Pages/${name}.tsx`]()).default,
  layout: AuthLayout,
});
