import cls from 'classnames';
import { PropsWithChildren } from 'react';

export const PageWidthConstraint = ({
  children,
  noPadding = false,
  className = '',
}: PropsWithChildren<{ noPadding?: boolean; className?: string }>) => {
  return <div className={cls(`max-w-screen-3xl w-full `, noPadding ? '' : '2xl:px-20 lg:px-10 sm:px-8 px-4', className)}>{children}</div>;
};
