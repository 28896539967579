import cls from 'classnames';
import { forwardRef, InputHTMLAttributes } from 'react';

export const CheckboxField = forwardRef<HTMLInputElement, InputHTMLAttributes<HTMLInputElement>>(({ className = '', value, ...props }, ref) => {
  const baseClasses = `
    form-checkbox h-6 w-6 text-red-500 transition rounded-md duration-150 ease-in-out text-cyan
    focus-ring focus:bg-white focus:shadow:none
  `;

  return <input type="checkbox" ref={ref} checked={value as unknown as boolean} className={cls(baseClasses, className)} {...props} />;
});
