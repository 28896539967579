import { Link } from '@inertiajs/react';
import { TippyProps } from '@tippyjs/react';
import cls from 'classnames';
import React, { ButtonHTMLAttributes, forwardRef, ReactNode } from 'react';
import { Tooltip } from 'Shared/Components/Tooltip';

export interface TableIconButtonProps extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'title'> {
  children: ReactNode;
  danger?: boolean;
  button?: boolean;
  title?: TippyProps['content'];
}

export const TableIconButton = forwardRef<HTMLButtonElement, TableIconButtonProps>(
  ({ children, title, className, danger = false, ...extendedProps }: TableIconButtonProps, ref) => {
    const baseClasses =
      'active:shadow-big-circle shadow-none active:shadow-gray-700 duration-500 p-2 rounded-lg bg-white transition-all text-grey-100 relative focus:z-10 border border-gray-600';
    const hoverClasses = danger ? 'focus-ring-red focus:text-red hover:text-red' : 'focus-ring focus:text-cyan hover:text-cyan';
    let renderedElement;

    if (React.isValidElement(children) && children.type === Link) {
      renderedElement = React.cloneElement(children, {
        ...extendedProps,
        ...children.props,
        className: cls(baseClasses, hoverClasses, className),
      });
    } else {
      renderedElement = (
        <button className={cls(baseClasses, hoverClasses, className)} {...extendedProps} ref={ref}>
          {children}
        </button>
      );
    }

    return title === undefined ? renderedElement : <Tooltip content={title}>{renderedElement}</Tooltip>;
  },
);
