import cls from 'classnames';
import { ElementType, forwardRef } from 'react';
import { BaseButton, BaseButtonProps, BaseButtonWrapper, defaultElement } from './BaseButton';

export const PrimaryButton: BaseButtonWrapper = forwardRef(function PrimaryButton<E extends ElementType = typeof defaultElement>(
  { className, ...otherProps }: BaseButtonProps<E>,
  ref: typeof otherProps.ref,
) {
  const baseClasses = `
      bg-cyan inline-flex items-center justify-center text-white transition ease-in-out duration-150 text-base px-6 rounded border border-cyan
      hover:bg-cyan-highlight focus:outline-none focus:ring-4 focus:ring-cyan focus:ring-opacity-20
      disabled:cursor-default disabled:opacity-50 disabled:bg-cyan
      active:shadow-big-circle shadow-none active:shadow-cyan/90 duration-500
    `;

  return <BaseButton as={defaultElement} className={cls(baseClasses, className)} ref={ref} {...otherProps} />;
});
