import { Field } from 'formik';
import { ComponentPropsWithoutRef, forwardRef, InputHTMLAttributes, useContext } from 'react';
import { FieldContext } from 'Shared/Contexts';
import { TextField as BaseTextField } from 'Shared/Components/FormFields/TextField';

export const TextField = forwardRef<HTMLInputElement, ComponentPropsWithoutRef<typeof BaseTextField>>((props, ref) => {
  const { name } = useContext(FieldContext);

  if (name === '') {
    throw new Error(TextField.displayName + ' with missing FieldWrapper component');
  }

  return <Field as={BaseTextField} ref={ref} name={name} {...props} />;
});

TextField.displayName = 'FormikTextField';
