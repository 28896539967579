import { InertiaFormProps } from '@inertiajs/react/types/useForm';
import { Formik as BaseFormik, FormikConfig, FormikErrors, FormikHelpers, FormikProps, FormikValues } from 'formik';
import * as React from 'react';
import { useEffect, useMemo } from 'react';
import { FormikContext } from 'Shared/Contexts';

export type ExtendedFormikProps<Values> = FormikProps<Values> & { submitDisabled: boolean };

export interface CustomFormikConfig<Values extends Record<string, unknown>> extends Omit<FormikConfig<Values>, 'onSubmit'> {
  inertia?: InertiaFormProps<Values>;
  initialValues: Values;
  initialValid: boolean;
  children?: ((props: ExtendedFormikProps<Values>) => React.ReactNode) | React.ReactNode;
  onSubmit(values: Values, formikHelpers: FormikHelpers<Values>): void | Promise<any>;
}

export const Formik = <Values extends FormikValues = FormikValues>({ inertia, initialValid, children, ...props }: CustomFormikConfig<Values>) => {
  const contextValue = useMemo(() => ({ validationSchema: props.validationSchema, inertia }), [props.validationSchema, inertia]);

  return (
    <FormikContext.Provider value={contextValue}>
      <BaseFormik {...props}>
        {(formProps) => {
          useEffect(() => inertia?.setData(formProps.values), [formProps.values]);
          useEffect(() => inertia && formProps.setErrors(inertia.errors as FormikErrors<Values>), [inertia?.errors]);
          useEffect(() => {
            inertia?.wasSuccessful && formProps.resetForm({ values: inertia.data as Values });
          }, [inertia?.wasSuccessful]);

          useEffect(() => {
            if (Object.keys(formProps.errors ?? {}).length) {
              console.log({ errors: formProps.errors, values: formProps.values });
            }
          }, [JSON.stringify(formProps.errors)]);

          return typeof children === 'function'
            ? children({
                ...formProps,
                isSubmitting: !!(formProps.isSubmitting || inertia?.processing),
                submitDisabled: inertia?.processing || formProps.isSubmitting || (!initialValid && !formProps.dirty) || !formProps.isValid,
              })
            : children;
        }}
      </BaseFormik>
    </FormikContext.Provider>
  );
};

Formik.displayName = 'CustomFormik';
